<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-row class="justify-center align-center">
              <v-col
                cols="12"
                md="7"
                class="text-center"
              >
                <v-avatar
                  rounded
                  size="120"
                  class="mx-auto"
                  width="200"
                  height="200"
                  color="primary"
                >
                  <v-img
                    v-if="imageData || dataComputed.image"
                    :src="imageData ? urlImage : dataComputed.image"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium"
                  >{{ t('notifications.image') }}</span>
                </v-avatar>
              </v-col>

              <v-col
                cols="12"
                md="5"
              >
                <!-- upload photo -->
                <div class="my-auto">
                  <v-file-input
                    v-model="imageData"
                    accept="image/png,image/jpg,image/jpeg"
                    :placeholder="t('notifications.select_image')"
                    :label="t('notifications.image')"
                    :prepend-icon="icons.mdiCamera"
                    outlined
                    dense
                    hide-details
                    @change="onFileSelected($event)"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-row class="justify-center align-center">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="dataComputed.name"
                  :label="t('sports.name')"
                  dense
                  outlined
                  :readonly="option===2"
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="dataComputed.product_categories_ids"
                  :label="t('products.categories')"
                  :items="computedProductCategories"
                  item-text="name"
                  item-value="id"
                  :menu-props="{ maxHeight: '400' }"
                  multiple
                  persistent-hint
                  dense
                  outlined
                  class="mb-0"
                  :append-icon="option===2 ? null : icons.mdiMenuDown"
                  :readonly="option===2"
                  :rules="[required]"
                >
                  <template #selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      label
                      dense
                      :input-value="data.selected"
                      @click="data.select"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-select>
              </v-col>

              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="dataComputed.description"
                  :label="t('sports.description')"
                  outlined
                  dense
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="dataComputed.tax"
              :label="t('transactions.tax')"
              prefix="%"
              dense
              outlined
              :readonly="option===2"
              :rules="[required, regexValidator(dataComputed.tax, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format')]"
              @input="e => {
                if (!charUnique(e, '.')) {
                  dataComputed.tax = null
                }
              }"
              @keypress="onlyNumberDot"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="dataComputed.price"
              :label="t('transactions.price')"
              prefix="$"
              dense
              outlined
              :readonly="option===2"
              :rules="[required, regexValidator(dataComputed.price, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format')]"
              @input="e => {
                if (!charUnique(e, '.')) {
                  dataComputed.price = null
                }
              }"
              @keypress="onlyNumberDot"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="dataComputed.mdwi"
              :label="t('products.mdwi')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required, integerValidator]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="dataComputed.stock"
              :label="t('products.stock')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="dataComputed.visibility"
              dense
              outlined
              :label="t('products.visibility')"
              :items="visibilityOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="dataComputed.status"
              dense
              outlined
              :label="t('status.status')"
              :items="statusOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="false"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="true"
            @onCancel="onCancelButton"
            @onNextTab="changeTab"
            @onSave="onSaveAction"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiMenuDown } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { required, emailValidator, integerValidator, regexValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { info } from '@core/utils/toasted'
import { onlyNumberDot } from '@core/utils/functionsHelpers'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    productCategories: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const form = ref(null)
    const dataComputed = computed(() => props.dataParams)

    const dataAcount = ref({})
    const initialData = {
      name: null,
      note: null,
      playing_time: null,
      status: null,
    }

    const urlImage = ref(null)
    const imageData = ref(null)
    const computedProductCategories = computed(() => props.productCategories)

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const visibilityOptions = computed(() => [
      { text: t('products.individual'), value: 'I' },
      { text: t('reservations.reservation'), value: 'R' },
      { text: t('products.both'), value: 'B' },
    ])

    const cleanImage = () => {
      imageData.value = null
      urlImage.value = null
    }

    const onFileSelected = e => {
      try {
        cleanImage()
        if (e && e.size >= 262144) info('Max size of 256K!')
        const type = e ? e.type.split('/').pop() : null
        if (e && (type === 'jpg' || type === 'jpeg' || type === 'png') && e.size < 262144) {
          imageData.value = e
          if (e) {
            urlImage.value = URL.createObjectURL(imageData.value)
          }
        } else {
          cleanImage()
        }
      } catch (err) {
        console.error('error', err)
      }
    }

    const onSaveAction = () => {
      if (!dataComputed.value.image && !imageData.value) {
        info('Select an imagen!')
      }

      if (form.value.validate() && (dataComputed.value.image || imageData.value)) {
        if (imageData.value) dataComputed.value.image = imageData.value
        emit('save', dataComputed.value)
      }
    }

    const onCancelButton = () => {
      form.value.reset()
      dataAcount.value = Object.assign(initialData, {
        name: null,
        note: null,
        playing_time: null,
        status: null,
      })
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    return {
      // data
      form,
      dataAcount,
      initialData,
      urlImage,
      imageData,
      computedProductCategories,

      // computed
      dataComputed,
      statusOptions,
      visibilityOptions,

      // methods
      onCancelButton,
      changeTab,
      onSaveAction,
      onFileSelected,
      cleanImage,

      // validations
      required,
      emailValidator,
      integerValidator,
      regexValidator,
      onlyNumberDot,

      // i18n
      t,

      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiMenuDown,
      },
    }
  },
}
</script>
