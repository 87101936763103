<template>
  <v-card
    id="product-form"
    min-height="350"
  >
    <!-- actions -->
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="goBack"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <product-tab-general
          :data-params="productData"
          :tab="tab"
          :option="option"
          :product-categories="productCategories"
          @changeTab="changeTab"
          @save="onSave"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiTableArrowLeft,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { error, success } from '@core/utils/toasted'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import {
  getProductsCategoriesByFacility,
  createProduct,
  editProduct,
  editProductImage,
  getProduct,
} from '@api'

// demos
import ProductTabGeneral from './ProductTabGeneral.vue'

export default {
  components: {
    ProductTabGeneral,
  },
  setup() {
    const { t } = useUtils()
    const { route, router } = useRouter()
    const { configOrganization, configFacility } = useSelectOptions()

    const tab = ref(0)
    const option = ref(1)
    const productId = ref(null)
    const pageData = ref()
    const filterData = ref()
    const productData = ref({})
    const imageLogo = ref(null)
    const postData = ref({
      facility_id: null,
      name: null,
      description: null,
      tax: null,
      price: null,
      mdwi: null,
      status: null,
      product_categories_ids: [],
      visibility: null,
    })
    const putData = ref({
      facility_id: null,
      name: null,
      description: null,
      tax: null,
      price: null,
      mdwi: null,
      status: null,
      product_categories_ids: [],
      visibility: null,
    })
    const productCategories = ref([])

    // tabs
    const tabs = ref([
      {
        title: 'General',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
    ])

    const changeTab = (tabNumber, validForm) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const updateImage = async image => {
      const formData = new FormData()
      if (image && productId.value) {
        formData.append('file', image)
        const responseLogo = await editProductImage(formData, productId.value)
        if (responseLogo.ok) {
          success(responseLogo.message)
        }
      }
    }

    const onSave = async data => {
      let resp = null
      imageLogo.value = data.image ? data.image : null
      if (option.value === 1) {
        postData.value = Object.assign(postData.value, {
          facility_id: configFacility.value,
          name: data.name,
          description: data.description,
          tax: data.tax,
          price: data.price,
          mdwi: data.mdwi,
          status: data.status,
          product_categories_ids: data.product_categories_ids,
          visibility: data.visibility,
        })

        resp = await createProduct(postData.value)

        if (resp.ok) {
          productId.value = resp.data.id
          await updateImage(imageLogo.value)
        }
      }

      if (option.value === 3) {
        putData.value = Object.assign(putData.value, {
          facility_id: configFacility.value,
          name: data.name,
          description: data.description,
          tax: data.tax,
          price: data.price,
          mdwi: data.mdwi,
          status: data.status,
          product_categories_ids: data.product_categories_ids,
          visibility: data.visibility,
        })
        resp = await editProduct(putData.value, data.id)

        if (resp.ok) {
          productId.value = data.id
          await updateImage(imageLogo.value)
        }
      }

      if (!option.value) {
        router.go(-1)
      }

      if (resp.ok) {
        router.go(-1)
        success(resp.message)
      } else {
        error(resp.message.text)
      }
    }

    const cancelAction = () => {
      tab.value = 0
      tabs.value[1].disabled = true
      tabs.value[2].disabled = true
      productData.value = {}
    }

    const goBack = () => {
      router.push({
        name: 'views-product-list',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    const fetchProductCategories = async () => {
      if (configFacility.value) {
        const response = await getProductsCategoriesByFacility(configFacility.value)
        productCategories.value = response.data
      }
    }

    // watch([configFacility], async () => {
    //   await fetchProductCategories(configFacility.value)
    // })

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      option.value = route.value.params.option || 2
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams
      productId.value = route.value.params.id

      if (productId.value) {
        const resp = await getProduct(productId.value)
        if (resp.ok) {
          productData.value = resp.data
        } else goBack()
      } else option.value = 1

      if (option.value !== 1) {
        tabs.value.forEach(e => {
          e.disabled = false
        })
      }

      if (option.value !== 1) {
        tabs.value.splice(1, 1)
      }

      if (option.value === 1) {
        productData.value.stock = 0
      }

      await fetchProductCategories(configFacility.value)
    })

    return {
      tab,
      tabs,
      option,
      productId,
      productData,
      postData,
      putData,
      productCategories,
      pageData,
      filterData,
      imageLogo,
      configFacility,

      changeTab,
      updateImage,
      onSave,
      cancelAction,
      goBack,
      fetchProductCategories,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiTableArrowLeft,
      },
    }
  },
}
</script>
